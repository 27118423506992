<template>
    <div>
        <Loader :isLoading="processingForm || isLoading" />
        <div class="commen-hader">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <h2>Create Job</h2>
                    </div>
                </div>
            </div>
        </div>

        <div
            v-if="formSubmitted"
            class="m-4 p-4 text-center"
            style="background: hsl(120, 100%, 90%)"
        >
            <p>
                Job submitted successfully. It will be live once approved by the admin.
                <br />
                <button class="primary-button" @click="$router.push({ name: 'my_jobs' })">
                    View My Jobs
                </button>
            </p>
        </div>

        <div v-else class="profile-section">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 col-md-12 col-sm-12">
                        <div class="profile-edit">
                            <h2><i class="fa fa-cogs"></i> JOB DETAILS</h2>
                            <form
                                class="about-me-profile"
                                enctype="multipart/form-data"
                                ref="reset"
                                @submit.prevent="submitJob()"
                            >
                                <h3>JOB DETAILS</h3>
                                <div class="form-group">
                                    <input
                                        type="text"
                                        class="form-control"
                                        name="job_title"
                                        placeholder="Job Title"
                                        v-model="job_title"
                                    />
                                    <FormError :title="formErrors.job_title" />
                                </div>

                                <div class="form-group">
                                    <input
                                        type="text"
                                        class="form-control"
                                        name="mobile "
                                        placeholder="Contact number"
                                        v-model="mobile"
                                    />
                                    <FormError :title="formErrors.mobile" />
                                </div>

                                <div class="form-group">
                                    <input
                                        type="email"
                                        class="form-control"
                                        name="email"
                                        placeholder="Email"
                                        v-model="email"
                                    />
                                    <FormError :title="formErrors.email" />
                                </div>

                                <!--<div class="form-group">-->
                                <!--<input type="text" class="form-control" name="company_name" placeholder=" Do we need a company? " v-model="company_name">-->
                                <!--  <span class="status" id="company_name-status"></span>-->
                                <!--  <div v-if="ress && ress.company_name" class="text-danger">{{ ress.company_name[0] }}</div>-->
                                <!--</div>-->

                                <!--<div class="form-group">-->
                                <!--<input type="text" class="form-control" name="company_name" placeholder=" Do we need a company? " v-model="company_name">-->
                                <!--  <span class="status" id="company_name-status"></span>-->
                                <!--  <div v-if="ress && ress.company_name" class="text-danger">{{ ress.company_name[0] }}</div>-->
                                <!--</div>-->

                                <!--<div class="form-group">-->
                                <!--<input type="text" class="form-control" name="star" placeholder="What is Star?" v-model="star">-->
                                <!--  <span class="status" id="star-status"></span>-->
                                <!--  <div v-if="ress && ress.star" class="text-danger">{{ ress.star[0] }}</div>-->
                                <!--</div>-->
                                <div class="row mb-3">
                                    <div class="col-md-6">
                                        <select
                                            class="reg-sel-1 w-100"
                                            name="state"
                                            v-model="state"
                                            v-on:change="getcity"
                                            required
                                        >
                                            <option disabled value="">State</option>
                                            <option
                                                v-for="state in states"
                                                :key="state.state_code"
                                                :value="state.state_code"
                                                >{{ state.state_name }}</option
                                            >
                                        </select>
                                        <FormError :title="formErrors.state" />
                                    </div>
                                    <div class="col-md-6">
                                        <select
                                            name="city"
                                            v-if="state"
                                            v-model="city"
                                            class="reg-sel-1 w-100"
                                            required
                                        >
                                            <option disabled value="">City</option>
                                            <option
                                                v-for="(city, index) in cities"
                                                :key="index"
                                                :value="city.city_code"
                                                >{{ city.city_name }}</option
                                            >
                                        </select>
                                    </div>

                                    <FormError :title="formErrors.city" />

                                    <!--<input type="text" class="form-control" name="city" placeholder="City" v-model="city">-->
                                    <!--  <span class="status" id="city-status"></span>-->
                                    <!--  <div v-if="ress && ress.city" class="text-danger">{{ ress.city[0] }}</div>-->
                                </div>
                                <div class="form-group">
                                    <label class="small mb-2 text-secondary"
                                        >Job Last Date(expiry date)</label
                                    >
                                    <input
                                        type="date"
                                        class="form-control"
                                        name="expiry_date"
                                        placeholder="Expiry Date"
                                        v-model="expiry_date"
                                    />
                                    <FormError :title="formErrors.expiry_date" />
                                </div>
                                <div class="form-group">
                                    <input
                                        type="number"
                                        class="form-control"
                                        name="salary_Offered"
                                        placeholder="Salary Offered (in Rs)"
                                        v-model="salary_Offered"
                                    />
                                    <FormError :title="formErrors.salary_Offered" />
                                </div>
                                <div class="form-group">
                                    <input
                                        type="text"
                                        class="form-control"
                                        name="overview"
                                        placeholder="Job overview"
                                        v-model="overview"
                                    />
                                    <FormError :title="formErrors.overview" />
                                </div>
                                <div class="form-group">
                                    <select
                                        name="experience"
                                        class="form-control"
                                        v-model="experience"
                                    >
                                        <option disabled value=""
                                            >Minimum experience expected</option
                                        >
                                        <option value="fresher">Fresher</option>
                                        <option value="1-5">1-5</option>
                                        <option value="6-10">6-10</option>
                                        <option value=">10">More than >10</option>
                                    </select>

                                    <FormError :title="formErrors.experience" />
                                </div>

                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-12">
                                            <label class="text-secondary mb-2 small"
                                                >Job Status</label
                                            >
                                        </div>
                                        <br />

                                        <div class="col-12">
                                            <select
                                                name="job_type"
                                                class="form-control col-12"
                                                v-model="job_type"
                                                style="width:47%;"
                                            >
                                                <option disabled value="">Job Type</option>
                                                <option value="Full Time">Full Time</option>
                                                <option value="Part Time">Part Time</option>
                                            </select>

                                            <FormError :title="formErrors.job_type" />
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label class="small text-secondary"
                                        >Job Requirements (Skills & Qualifications)</label
                                    >
                                    <vue-editor
                                        style="height:10%"
                                        :editorToolbar="customToolbar"
                                        v-model="must_have"
                                    ></vue-editor>
                                </div>
                                <div style="height: 30px"></div>
                                <FormError :title="formErrors.must_have" />

                                <div class="form-group py-2">
                                    <button type="submit" class="primary-button">
                                        {{ processingForm ? "Please wait..." : "SUBMIT NOW" }}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <LeftBar />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios"
import { HTTP } from "../_helper/http-constants"
import LeftBar from "../components/leftbar.vue"
import { VueEditor } from "vue2-editor"
import { mapState, mapGetters, mapActions } from "vuex"
import scrollTop from "./utils/scrollTop"
import request from "../apis/request"
import Loader from "./Loader"
import FormError from "./utils/FormError.vue"

export default {
    name: "dashboard",
    components: {
        LeftBar,
        VueEditor,
        Loader,
        FormError,
    },
    data() {
        return {
            plans: "",
            product: "",
            enquire_count: "",
            isLoading: false,
            ress: {},

            job_title: "",
            company_name: "",
            job_type: "",
            star: "",
            city: "",
            state: "",
            cities: [],
            states: [],
            salary_Offered: "",
            overview: "",
            experience: "",
            must_have: "",
            //   image:"",
            expiry_date: "",
            email: "",
            mobile: "",
            jobs_count: "",

            customToolbar: [
                ["bold", "italic", "underline"],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ align: "left" }, { align: "center" }, { align: "right" }, { align: "justify" }],
            ],

            action: "/createjob",

            //   imgsrc: 'http://localhost/backend/public/admin_profile/',
            // imgsrc: 'http://103.212.120.205/~dev/public/admin_profile/',
            imgsrc: axios.defaults.uploadURL,
            processingForm: false,
            formSubmitted: false,
            startValidation: false,
            formErrors: {},
        }
    },
    watch: {
        job_title(e) {
            this.updateErrors()
        },
        job_type(e) {
            this.updateErrors()
        },
        city(e) {
            this.updateErrors()
        },
        state(e) {
            this.updateErrors()
        },
        salary_Offered(e) {
            this.updateErrors()
        },
        overview(e) {
            this.updateErrors()
        },
        experience(e) {
            this.updateErrors()
        },
        must_have(e) {
            this.updateErrors()
        },
        expiry_date(e) {
            this.updateErrors()
        },
        email(e) {
            this.updateErrors()
        },
        mobile(e) {
            this.updateErrors()
        },
    },
    computed: {
        ...mapState("auth", {
            user: "user",
        }),
        ...mapGetters("auth", {
            isMaterialTester: "isMaterialTester",
        }),
    },
    methods: {
        ...mapActions("auth", {
            logoutUser: "logout",
        }),
        hasHistory() {
            return window.history.length > 2
        },

        getcity() {
            axios.get("/api/city/" + this.state).then((response) => (this.cities = response.data))
        },
        async checkjobPostingAllowed() {
            try {
                const res = await request.get("/check-job-posting-allowed")
                const data = res.data.result
            } catch (err) {
                console.log("error in posting job", err)
                const message = err.response && err.response.data && err.response.data.message
                if (message && err.response.data.operational) {
                    this.$toasted.error(message || "You are not allowed to post surplus.")
                } else {
                    this.$toasted.error("Something went wrong")
                }
                if (this.hasHistory()) {
                    this.$router.go(-1)
                } else {
                    this.$route.push({ name: "landing" })
                }
            }
        },
        async logout() {
            await this.logoutUser()
            this.$router.push({ name: "landing" })
        },

        previewFiles(event) {
            console.log(event)
            console.log(event.target.files[0])

            this.image = event.target.files[0]
            // console.log(this.image)
        },
        updateErrors() {
            if (!this.startValidation) return
            this.formErrors = {}
            if (!this.job_title) {
                this.formErrors.job_title = "Job Title is required"
            }
            //   if (this.company_name) {
            //     document.getElementById("company_name-status").innerHTML =
            //       "<span class='warning' style='color: red;'>You need to fill out this form.</span>";
            //   }

            if (!this.job_type) {
                this.formErrors.job_type = "Job Type is required"
            }
            //   if (this.star) {
            //     document.getElementById("star-status").innerHTML =
            //       "<span class='warning' style='color: red;'>You need to fill out this form.</span>";
            //   }
            if (!this.city) {
                this.formErrors.city = "City is required"
            }
            if (!this.state) {
                this.formErrors.state = "State is required"
            }

            if (!this.salary_Offered) {
                this.formErrors.salary_Offered = "Salary Offered is required"
            }
            if (!this.overview) {
                this.formErrors.overview = "Job Overview is required"
            }
            if (!this.experience) {
                this.formErrors.experience = "Job experience field is required"
            }
            if (!this.must_have) {
                this.formErrors.must_have = "This field is required"
            }
            if (!this.expiry_date) {
                this.formErrors.expiry_date = "Expiry date is required"
            }
            if (!this.mobile) {
                this.formErrors.expiry_date = "Mobile number is required"
            }
            if (!this.email) {
                this.formErrors.expiry_date = "Email is required"
            }
            //   if (this.image) {
            //     document.getElementById("image-status").innerHTML =
            //       "<span class='warning' style='color: red;'>You need to fill out this form.</span>";
            //   }

            this.validateEmail()
            this.validatemobile()
        },
        submitJob(e) {
            if (this.processingForm) return
            this.startValidation = true
            this.updateErrors()
            if (
                !(
                    (
                        this.job_title &&
                        //   this.company_name &&
                        this.job_type &&
                        //   this.star &&
                        this.city &&
                        this.state &&
                        this.salary_Offered &&
                        this.overview &&
                        this.must_have &&
                        this.expiry_date &&
                        this.experience &&
                        this.validateEmail() &&
                        this.validatemobile()
                    )
                    //   this.image
                )
            ) {
                this.$toasted.error("Please correct the errors before submitting.")
                return
            }

            this.ress = {}

            const form = new FormData()
            form.append("job_title", this.job_title)
            // form.append('company_name',this.company_name);
            form.append("job_type", this.job_type)
            // form.append('star',this.star);
            form.append("city", this.cities.find((item) => item.city_code === this.city).city_name)
            form.append(
                "state",
                this.states.find((item) => item.state_code === this.state).state_name
            )
            form.append("salary_Offered", this.salary_Offered)
            form.append("overview", this.overview)
            // form.append('image',this.image);
            form.append("experience", this.experience)
            form.append("must_have", this.must_have)
            form.append("expiry_date", this.expiry_date)
            form.append("email", this.email)
            form.append("contact", this.mobile)

            //console.log(this.image);

            this.processingForm = true
            request
                .post(this.action, form)
                .then((res) => {
                    // window.localStorage.setItem('isLogged', true);
                    // window.localStorage.setItem('user', JSON.stringify(res.data));
                    //console.log("localstorage", JSON.parse(window.localStorage.getItem('user')));
                    // this.$router.push({name: 'dashboard'});
                    //  console.log("ok")
                    console.log(res)
                    this.processingForm = false
                    this.formSubmitted = true
                    this.$refs.reset.reset(),
                        this.$toasted.success("Job Created in successfully", {
                            keepOnHover: true,
                            iconPack: "fontawesome",
                            icon: "check",
                            theme: "toasted-primary",
                            // timerProgressBar: true,
                            duration: 3000,
                        })

                    //   alert('Profile Update successfully!');
                })
                .catch((res) => {
                    this.processingForm = false
                    this.$toasted.error("Something went wrong. Please try again later.")
                    //alert('err');
                    if (res.response.status === 422) {
                        this.ress = res.response.data.errors || {}
                    }
                    return res
                })
        },

        validateEmail: function() {
            const regMail = /^([_a-zA-Z0-9-]+)(\.[_a-zA-Z0-9-]+)*@([a-zA-Z0-9-]+\.)+([a-zA-Z]{2,3})$/
            let res = regMail.test(this.email)
            let msg
            if (res === false) msg = "Email is not valid"
            this.formErrors.email = msg
            return res
        },

        validatemobile: function() {
            let res = true
            console.log("validate mobile running")
            let digitCount = this.mobile.length
            for (let i = 0; i < this.mobile.length; i++) {
                if (!Number.isInteger(Number(this.mobile[i]))) {
                    res = false
                }
            }
            let msg
            if (res == false) msg = "Please enter only digits without spaces."
            else if (digitCount != 10) msg = "Mobile no. must be exactly 10 digits"
            this.formErrors.mobile = msg
            return res && digitCount == 10
        },
    },

    async mounted() {
        scrollTop()
        if (this.isMaterialTester) {
            this.$toasted.error("Sorry! You are not allowed to post jobs!")
            this.$router.push({ name: "landing" })
        }
        await this.checkjobPostingAllowed()
        axios.get("/api/state").then((response) => (this.states = response.data))
    },
    beforeDestroy() {
        this.isLoading = true
        console.log("add job unmounting")
    },
}
</script>

<style>
.submit_button {
    padding: 12px 35px;
    width: auto;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    color: #fff;
    background-color: #8c0526;
    text-align: center;
    margin: 30px auto;
    display: block;
    margin-top: 60px !important;
}
/*body{
  background-color:#f5f5f5;
}*/
.imagePreview {
    width: 100%;
    height: 180px;
    background-position: center center;
    background: url(http://cliquecities.com/assets/no-image-e3699ae23f866f6cbdf8ba2443ee5c4e.jpg);
    background-color: #fff;
    background-size: cover;
    background-repeat: no-repeat;
    display: inline-block;
    box-shadow: 0px -3px 6px 2px rgba(0, 0, 0, 0.2);
}
/*.btn-primary
{
  display:block;
  border-radius:0px;
  box-shadow:0px 4px 6px 2px rgba(0,0,0,0.2);
  margin-top:-5px;
}*/
.imgUp {
    margin-bottom: 15px;
}
/*.del
{
  position:absolute;
  top:0px;
  right:15px;
  width:30px;
  height:30px;
  text-align:center;
  line-height:30px;
  background-color:rgba(255,255,255,0.6);
  cursor:pointer;
}*/
/*.imgAdd
{
  width:30px;
  height:30px;
  border-radius:50%;
  background-color:#4bd7ef;
  color:#fff;
  box-shadow:0px 0px 2px 1px rgba(0,0,0,0.2);
  text-align:center;
  line-height:30px;
  margin-top:0px;
  cursor:pointer;
  font-size:15px;
}*/

.ql-editor {
    min-height: 60px !important;
}
</style>
